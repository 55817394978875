function initMode() {
  const modeToggle = document.querySelector("#toggle-mode-cb");
  const modeToggleMobile = document.querySelector("#toggle-mode-cb-mobile");

  let theme = window.localStorage.getItem("theme");

  if (!theme) {
    theme = "dark";
    window.localStorage.setItem("theme", theme);
  }

  if (theme == "light") {
    document.body.classList.remove("mode-dark");
    document.body.classList.add("mode-light");
  } else if (theme == "dark") {
    document.body.classList.remove("mode-light");
    document.body.classList.add("mode-dark");

    modeToggle.checked = true;

    if (modeToggleMobile) {
      modeToggleMobile.checked = true;
    }
  }

  changeModeImage(theme);
}

function changeMode() {
  let theme = window.localStorage.getItem("theme");
  theme = theme == "light" ? "dark" : "light";

  document.body.classList.toggle("mode-light");
  document.body.classList.toggle("mode-dark");

  window.localStorage.setItem("theme", theme);
  changeModeImage(theme);
}

function changeModeImage(theme) {
  const images = document.querySelectorAll("img[light]");

  images.forEach((image) => {
    image.src = image.getAttribute(theme);
  });
}

export { initMode, changeMode };
