<template>
  <footer
    v-if="isSmall"
    class="footer is-small"
    :class="{ black: isBlack, white: isWhite }"
  >
    <div class="container">
      <div class="row copyright">
        <div class="col d-flex align-items-center nav-social-media">
          <span class="mr-3">SHARE</span>

          <a
            href="https://api.whatsapp.com/send?text=Space Walk https://spacewalk.auroraweb.id"
            rel="noopener"
            target="_blank"
          >
            <font-awesome-icon :icon="['fab', 'whatsapp']" class="mr-4" />
          </a>
          <a
            href="https://twitter.com/intent/tweet?text=Space Walk https://spacewalk.auroraweb.id"
            rel="noopener"
            target="_blank"
          >
            <font-awesome-icon :icon="['fab', 'twitter']" class="mr-4 stroke" />
          </a>
          <a
            href="https://www.instagram.com/orbit4it/"
            rel="noopener"
            target="_blank"
          >
            <font-awesome-icon :icon="['fab', 'instagram']" class="mr-4" />
          </a>
          <a
            href="https://www.facebook.com/sharer/sharer.php?u=https://spacewalk.auroraweb.id"
            rel="noopener"
            target="_blank"
          >
            <font-awesome-icon :icon="['fab', 'facebook-f']" class="stroke" />
          </a>
        </div>

        <div class="col-md text-md-right mt-2 mt-md-0">
          <span>All copyright reserved by spacewalk.</span>
        </div>
      </div>
    </div>
  </footer>
  <footer v-else class="footer" :class="{ black: isBlack, white: isWhite }">
    <div class="container">
      <div class="row align-items-md-center nav-footer">
        <div class="col-lg-2 mb-4 mb-lg-0">
          <router-link :to="{ name: 'Home' }" class="d-block">
            <img
              src="/assets/icons/logo-black.svg"
              alt="Space Walk"
              light="/assets/icons/logo-black.svg"
              dark="/assets/icons/logo-white.svg"
              width="120"
              height="70"
            />
          </router-link>
        </div>

        <div class="col-md-4 col-lg-2 d-flex flex-column">
          <router-link
            :to="{ name: 'Home' }"
            data-en="Home"
            data-id="Beranda"
            class="mb-1"
            :class="[$route.name != 'Home' ? 'not-active' : '']"
            >Home</router-link
          >
          <router-link
            :to="{ name: 'Profile' }"
            data-en="Profile"
            data-id="Profil"
            class="mb-1"
            >Profile</router-link
          >
          <router-link
            :to="{ name: 'Education' }"
            data-en="Education"
            data-id="Pembelajaran"
            >Education</router-link
          >
        </div>

        <div class="col-md-4 col-lg-3 col-xl-2 d-flex flex-column mt-3 mt-md-0">
          <router-link
            :to="{ name: 'Figures' }"
            data-en="Figures"
            data-id="Tokoh"
            class="mb-1"
            >Figures</router-link
          >
          <router-link
            :to="{ name: 'Constellations' }"
            data-en="Constellations"
            data-id="Rasi Bintang"
            class="mb-1"
            >Constellations</router-link
          >
          <router-link
            :to="{ name: 'SkyObjects' }"
            data-en="Sky Objects"
            data-id="Benda Langit"
            >Sky Objects</router-link
          >
        </div>

        <div class="col-md-4 col-lg-2 d-flex flex-column mt-3 mt-md-0">
          <router-link
            :to="{ name: 'SpaceShips' }"
            data-en="Space Ships"
            data-id="Pesawat Luar Angkasa"
            class="mb-1"
            >Space Ships</router-link
          >

          <router-link
            :to="{ name: 'Resources' }"
            data-en="Resources"
            data-id="Daftar Pustaka"
            >Resources</router-link
          >
        </div>

        <div
          class="
            col
            d-flex
            justify-content-lg-end
            nav-social-media
            mt-3 mt-lg-0
          "
        >
          <a
            href="https://api.whatsapp.com/send?text=Space Walk https://spacewalk.auroraweb.id"
            rel="noopener"
            target="_blank"
          >
            <font-awesome-icon :icon="['fab', 'whatsapp']" class="mr-4" />
          </a>
          <a
            href="https://twitter.com/intent/tweet?text=Space Walk https://spacewalk.auroraweb.id"
            rel="noopener"
            target="_blank"
          >
            <font-awesome-icon :icon="['fab', 'twitter']" class="mr-4 stroke" />
          </a>
          <a
            href="https://www.instagram.com/orbit4it/"
            rel="noopener"
            target="_blank"
          >
            <font-awesome-icon :icon="['fab', 'instagram']" class="mr-4" />
          </a>
          <a
            href="https://www.facebook.com/sharer/sharer.php?u=https://spacewalk.auroraweb.id"
            rel="noopener"
            target="_blank"
          >
            <font-awesome-icon :icon="['fab', 'facebook-f']" class="stroke" />
          </a>
        </div>
      </div>

      <div class="row mt-5 copyright">
        <div class="col-md">
          <a
            href="https://orbit.smkn4bdg.sch.id/"
            rel="noopener"
            target="_blank"
          >
            Design by ORBIT4IT
          </a>
        </div>

        <div class="col-md text-md-right">
          <span>All copyright reserved by spacewalk.</span>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "Footer",
  props: {
    isBlack: Boolean,
    isWhite: Boolean,
    isSmall: Boolean,
  },
};
</script>