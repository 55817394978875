function initLanguage() {
  const contents = document.querySelectorAll("[data-ID]");
  let language = window.localStorage.getItem("language");

  if (!language) {
    language = "ID";
    window.localStorage.setItem("language", language);
  }

  contents.forEach((content) => {
    content.innerHTML = content.getAttribute(`data-${language}`);
  });

  changeViewLanguage(language);
}

function changeLanguage(e) {
  const contents = document.querySelectorAll("[data-ID]");
  const language = e.target.getAttribute("data-lang");

  window.localStorage.setItem("language", language);
  contents.forEach((content) => {
    content.innerHTML = content.getAttribute(`data-${language}`);
  });

  changeViewLanguage(language);
}

function changeViewLanguage(language) {
  if (language == "ID") {
    document.querySelector(".language-id").classList.add("active");
    document.querySelector(".language-en").classList.remove("active");
  } else {
    document.querySelector(".language-en").classList.add("active");
    document.querySelector(".language-id").classList.remove("active");
  }
}

export { initLanguage, changeLanguage };
