<template>
  <div class="navbar-container container">
    <header
      class="header d-flex align-items-center justify-content-between my-3"
      :class="{
        'header-white': isWhite,
        'header-menu-white': isWhiteMenu,
        'header-black': isBlack,
      }"
    >
      <router-link :to="{ name: 'Home' }" class="d-block">
        <img
          v-if="isWhite"
          src="/assets/icons/logo-white.svg"
          alt="Space Walk"
          width="80"
          height="44"
        />
        <img
          v-else-if="isBlack"
          src="/assets/icons/logo-black.svg"
          alt="Space Walk"
          width="80"
          height="44"
        />
        <img
          v-else
          src="/assets/icons/logo-black.svg"
          alt="Space Walk"
          light="/assets/icons/logo-black.svg"
          dark="/assets/icons/logo-white.svg"
          width="80"
          height="44"
        />
      </router-link>

      <div class="d-flex align-items-center">
        <div class="mr-3 d-none d-md-block" v-if="!isSkyObject">
          <input type="checkbox" id="toggle-mode-cb" />
          <label id="toggle-mode" for="toggle-mode-cb" class="mode-toggle">
            <span class="toggle-border">
              <span class="toggle-indicator"></span>
            </span>
          </label>
        </div>

        <div ref="language" class="language d-flex align-items-center">
          <a data-lang="EN" class="cursor-pointer language-en">EN</a>
          <span class="line mx-2"></span>
          <a data-lang="ID" class="cursor-pointer language-id">ID</a>
        </div>

        <a
          v-if="isFullScreen"
          @click.prevent="fullscreen"
          class="
            cursor-pointer
            text-decoration-none
            d-flex
            align-items-center
            full-screen
            ml-4
          "
        >
          FULLSCREEN
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            class="ml-2"
          >
            <path d="M22 8.89644V2H15.1036" stroke="white" stroke-width="3" />
            <path d="M2 8.89644V2H8.89644" stroke="white" stroke-width="3" />
            <path
              d="M2 15.1032V21.9997H8.89644"
              stroke="white"
              stroke-width="3"
            />
            <path
              d="M22 15.1032V21.9997H15.1036"
              stroke="white"
              stroke-width="3"
            />
          </svg>
        </a>

        <a
          v-else
          @click.prevent="showSidebar"
          class="cursor-pointer d-flex align-items-center nav-toggle ml-4"
        >
          <div class="burger-icon">
            <div class="one"></div>
            <div class="two"></div>
          </div>
        </a>
      </div>
    </header>
  </div>
</template>

<script>
// Services
import { initLanguage, changeLanguage } from "@/services/Language.js";
import { initMode, changeMode } from "@/services/Mode.js";

export default {
  name: "Navbar",
  props: {
    isWhite: Boolean,
    isWhiteMenu: Boolean,
    isBlack: Boolean,
    isSkyObject: Boolean,
    isFullScreen: Boolean,
  },
  data() {
    return {
      language: null,
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      this.language = this.$refs.language;

      if (this.language) {
        initLanguage();
        this.language
          .querySelectorAll("a")
          .forEach((link) => link.addEventListener("click", changeLanguage));
      }

      // Dark Mode
      const modeToggle = document.querySelector("#toggle-mode-cb");
      const modeToggleMobile = document.querySelector("#toggle-mode-cb-mobile");

      if (modeToggle) {
        initMode();
        modeToggle.addEventListener("change", changeMode);

        if (modeToggleMobile) {
          modeToggleMobile.addEventListener("change", changeMode);
        }
      }
    },
    showSidebar() {
      const sidebar = document.querySelector(".sidebar");
      const sidebarBlock = document.querySelectorAll(".sidebar-block");

      sidebar.classList.toggle("active");

      if (sidebar.classList.contains("active")) {
        document.body.classList.toggle("overflow-hidden");
        setTimeout(() => {
          sidebar.querySelector(".container").classList.toggle("active");

          sidebarBlock.forEach((block) => {
            block.classList.toggle("animate");
          });
        }, 750);
      } else {
        sidebar.querySelector(".container").classList.toggle("active");
        document.body.classList.toggle("overflow-hidden");

        sidebarBlock.forEach((block) => {
          block.classList.toggle("animate");
        });
      }
    },
    fullscreen() {
      this.$emit("exitDetail");
    },
  },
};
</script>