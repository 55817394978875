<template>
  <div class="sidebar">
    <div class="container">
      <header
        class="header d-flex align-items-center justify-content-between my-3"
      >
        <router-link :to="{ name: 'Home' }" class="d-block">
          <img
            v-lazy="'/assets/icons/logo-white.svg'"
            alt="Space Walk"
            width="80"
            height="44"
          />
        </router-link>

        <a
          @click.prevent="showSidebar"
          class="
            nav-toggle
            btn btn-close
            d-flex
            align-items-center
            p-3
            rounded-pill
          "
        >
          <span data-en="CLOSE" data-id="TUTUP">CLOSE</span>
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            class="ml-2"
          >
            <path d="M15 1L1 15M1 1L15 15" stroke="white" stroke-width="2.4" />
          </svg>
        </a>
      </header>

      <div class="row">
        <div
          v-for="(page, i) in pages"
          :key="`sidebar-page${i}`"
          class="col-md-6 col-lg-4 mb-3 mb-md-2 sidebar-block"
          :class="`sidebar-block-${i + 1}`"
        >
          <router-link :to="{ name: page.link }" class="nav-sidebar">
            <div
              class="
                title
                text-uppercase
                d-flex
                justify-content-center
                align-items-center
                text-center
              "
              :style="`background-image: url(${page.img});`"
            >
              <span :data-en="page.en" :data-id="page.id">{{
                page.title
              }}</span>
            </div>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Sidebar",
  data() {
    return {
      pages: [
        {
          title: "Home",
          en: "Home",
          id: "Beranda",
          img: "/assets/img/home/bg-home.jpg",
          link: "Home",
        },
        {
          title: "Figures",
          en: "Figures",
          id: "Tokoh",
          img: "/assets/img/home/bg-figures.jpg",
          link: "Figures",
        },
        {
          title: "Profile",
          en: "Profile",
          id: "Profil",
          img: "/assets/img/home/bg-profile.jpg",
          link: "Profile",
        },
        {
          title: "Education",
          en: "Education",
          id: "Pembelajaran",
          img: "/assets/img/home/bg-education.jpg",
          link: "Education",
        },
        {
          title: "Constellations",
          en: "Constellations",
          id: "Rasi bintang",
          img: "/assets/img/home/bg-constellations.jpg",
          link: "Constellations",
        },
        {
          title: "Sky Objects",
          en: "Sky Objects",
          id: "Benda langit",
          img: "/assets/img/home/bg-sky-objects.jpg",
          link: "SkyObjects",
        },
        {
          title: "Space Ships",
          en: "Space Ships",
          id: "Pesawat luar angkasa",
          img: "/assets/img/home/bg-space-ships.jpg",
          link: "SpaceShips",
        },
      ],
    };
  },
  created() {
    this.pages = this.pages.filter((page) => page.link != this.$route.name);
  },
  destroyed() {
    document.body.classList.remove("overflow-hidden");
  },
  methods: {
    showSidebar() {
      const sidebar = document.querySelector(".sidebar");
      const sidebarBlock = document.querySelectorAll(".sidebar-block");

      sidebar.classList.toggle("active");

      if (sidebar.classList.contains("active")) {
        document.body.classList.toggle("overflow-hidden");
        setTimeout(() => {
          sidebar.querySelector(".container").classList.toggle("active");

          sidebarBlock.forEach((block) => {
            block.classList.toggle("animate");
          });
        }, 750);
      } else {
        sidebar.querySelector(".container").classList.toggle("active");
        document.body.classList.toggle("overflow-hidden");

        sidebarBlock.forEach((block) => {
          block.classList.toggle("animate");
        });
      }
    },
  },
};
</script>